import { Query } from '@root/src/data/graphql/graphql-types'
import { graphql, navigate } from 'gatsby'
import React from 'react'
import { SEO } from '../components/legacy/mol.seo/seo.component'
import { ContactUsPageContent } from '../components/org.contact-us-page-content/contact-us-page-content.component'
import { ClientDoctorSelectContext } from '../context/client-doctor-select'
import IndexLayout from '../components/org.layout/layout.component'
import { PageProps } from '../utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getClientAndDoctorUrl } from 'utils/url'
interface ContactPageProps {}

const ContactPage: React.FunctionComponent<PageProps<ContactPageProps, Query>> = props => {
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
  const seoData = props.data.cosmicjsFaleConosco.metadata.seo
  const siteUrl = props.data.site.siteMetadata.siteUrl

  return (
    <ClientDoctorSelectContext.Provider
      value={{
        clientUrl: clientPath,
        doctorUrl: doctorPath
      }}
    >
      <IndexLayout location={props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(siteUrl, appPaths.contact.path),
            title: seoData.titulo,
            image: seoData.imagem.url,
            imageAlt: seoData.imagemAlt,
            description: seoData.descricao
          }}
        />
        <ContactUsPageContent data={props.data.cosmicjsFaleConosco} isDoctor={false} />
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default ContactPage

export const query = graphql`
  query FaleConoscoDetailClient {
    site {
      ...SiteUrl
    }

    cosmicjsFaleConosco(slug: { eq: "cliente" }) {
      id
      metadata {
        faqTitulo
        faqConteudo
        atendimentoTelefoneTitulo
        atendimentoTelefoneConteudo
        atendimentoOnlineTitulo
        atendimentoOnlineConteudo
        email_padrao
        assuntos {
          tituloAssunto
          emailAssunto
        }
        seo {
          titulo
          descricao
          imagem {
            url
          }
          imagemAlternativa
        }
      }
    }
  }
`
