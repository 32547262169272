import {
  buildSendEmailRequest,
  sendEmailUrl
} from '@app/data/http/request-builder/send-email.request'
import { CosmicjsFaleConosco, Query } from '@root/src/data/graphql/graphql-types'
import { buildEmailHTML } from '@root/src/utils/email-html'
import { Button, Col, Divisor, Grid, H2, Row, Separator } from 'atomic'
import { EnhancedSelect } from 'atomic/legacy/atm.enhanced-select/enhanced-select.component'
import { FloatingLabel } from 'atomic/legacy/atm.floating-label/floating-label.component'
import { RadioField } from 'atomic/legacy/atm.radio'
import { TextAreaField, TextField } from 'atomic/legacy/atm.text-field'
import { Form, FormData, Validators } from 'atomic/legacy/obj.form'
import { graphql, navigate, StaticQuery } from 'gatsby'
import React, { useEffect, useLayoutEffect } from 'react'
import flashDispatcherService from '../legacy/obj.flash-wrapper/flash-dispatcher.service'
import { HttpPostContainer } from '../legacy/obj.http-container/http-post-container.component'
import TitleWithBreadcrumbRow from '../org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { useTimestamp } from 'atomic/obj.custom-hooks/timestamp.hook'
import sendEmailNew from '../../services/sendEmail.js'

import { Dialog, DialogTitle, DialogActions } from '@material-ui/core'

import * as S from './styles'

import SuccessIcon from '@assets/images/sucessgif.gif'

interface ContactUsPageContentProps {
  data: CosmicjsFaleConosco
  isDoctor: boolean
}

interface ContactPageFormData {
  subject: { label: string; value: string }
  contactPreference: string
  email: string
  name: string
  cpf: string
  birthDate: string
  phone: string
  fileNumber: string
  company: string
  message: string
}

enum RadioOptions {
  email = 'email',
  telefone = 'phone'
}

export const ContactUsPageContent: React.FunctionComponent<ContactUsPageContentProps> = props => {
  const [subjectSelected, setSubjectSelected] = React.useState({
    label: '',
    value: 0
  })
  const [formTimestamp, updateFormTimestamp] = useTimestamp() as [number, () => void]

  const [radioValue, setRadioValue] = React.useState(RadioOptions.email)

  const [showSucessMessage, setShowSucessMessage] = React.useState(true)

  const handleSuccess = () => {
    if (showSucessMessage) {
      flashDispatcherService.dispatchMessage('E-mail enviado com sucesso!', 'success')
    }

    updateFormTimestamp()
  }

  const handleRadioChange = value => setRadioValue(value)

  const metadata = props.data.metadata
  const subjects = metadata.assuntos
  const subjectsSelect = subjects.map((assunto, index) => ({
    label: assunto.tituloAssunto,
    value: index
  }))

  const [emailValue, setEmailValue] = React.useState('')

  const [telefoneValue, setTelefoneValue] = React.useState('')

  const [successSendEmail, setSuccessSendEmail] = React.useState('')

  const [openModal, setOpenModal] = React.useState(false)

  useEffect(() => {
    navigate('/')
  }, [])

  return (
    <>
      {/* <Dialog aria-labelledby="customized-dialog-title" open={openModal}>
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            window.location.reload()
          }}
        ></DialogTitle>
        <S.DialogContent>
          {/* <img src={SuccessIcon} /> */}
      {/* <span>E-mail enviado com sucesso!</span> */}
      {/* </S.DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              window.location.reload()
            }}
          >
            Entendi
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* <Grid>
        <TitleWithBreadcrumbRow title="Fale conosco" />

        <Row>
          <Col xs={12} sm={12} md={7} lg={7}>
            <Separator />

            <HttpPostContainer url={sendEmailUrl} onSuccess={handleSuccess}>
              {(post, state) => {
                const handleSubmit = async (formData: FormData<ContactPageFormData>) => {
                  if (Object.keys(formData.error).length !== 0) {
                    return
                  }

                  const data = formData.data
                  const email = subjects[data.subject.value].emailAssunto
                  const emailSubject = `[${data.subject.label}] - ${data.name} - ${process.env.GATSBY_MARCA}`
                  const emailContent = buildEmail(data)
                  let request = buildSendEmailRequest(email, emailSubject, emailContent)

                  if (subjectSelected.label.substring(0, 9) === 'Ouvidoria' && data.cpf === '') {
                    flashDispatcherService.dispatchMessage('Por favor, preencha o CPF!', 'warning')
                    return
                  }

                  //request antigo
                  // await post(request)

                  setShowSucessMessage(false)

                  if (metadata.email_padrao) {
                    request = buildSendEmailRequest(
                      metadata.email_padrao,
                      emailSubject,
                      emailContent
                    )
                    //request antigo
                    // await post(request)

                    await sendEmailNew({
                      recipient: email,
                      message: data.message,
                      subject: emailSubject,
                      name: data.name,
                      cpf: data.cpf,
                      birthDate: data.birthDate,
                      preferencialContact: radioValue,
                      email: data.email,
                      phone: data.phone,
                      medicalRecordId: data.fileNumber,
                      company: data.company,
                      form: 'contact-us'
                    })
                      .then(res => {
                        setOpenModal(true)
                      })
                      .catch(error => {
                        flashDispatcherService.dispatchMessage(
                          'Ocorreu algum erro, verifique!',
                          'warning'
                        )
                      })
                  }
                }

                return (
                  <Form id="form-contact-us-page" onSubmit={handleSubmit} key={formTimestamp}>
                    <Row mb center="xs">
                      <Col xs>
                        <Form.Field
                          name="subject"
                          validators={[Validators.Required('Campo obrigatório')]}
                        >
                          <FloatingLabel label={'Assunto'}>
                            <EnhancedSelect
                              options={subjectsSelect}
                              onChange={e => setSubjectSelected(e)}
                            />
                          </FloatingLabel>
                        </Form.Field>

                        <Separator />
                      </Col>
                    </Row>

                    <Row mb center="xs">
                      <Col xs>
                        <Form.Field
                          name="name"
                          validators={[Validators.Required('Campo obrigatório')]}
                        >
                          <FloatingLabel label={'Nome completo'}>
                            <TextField id="text-field-name" type={'text'} autoComplete="name" />
                          </FloatingLabel>
                        </Form.Field>

                        <Separator />
                      </Col>
                    </Row>

                    {subjectSelected.label.substring(0, 9) === 'Ouvidoria' ? (
                      <>
                        {' '}
                        <Row mb center="xs">
                          <Col xs>
                            <Form.Field
                              name="cpf"
                              validators={[
                                Validators.CpfRegex('O CPF preenchido não é válido'),
                                Validators.Required('Campo obrigatório')
                              ]}
                            >
                              <FloatingLabel label={'CPF'}>
                                <TextField id="text-field-cpf" type={'text'} kind="cpf" />
                              </FloatingLabel>
                            </Form.Field>

                            <Separator />
                          </Col>
                        </Row>
                        <Row mb center="xs">
                          <Col xs>
                            <Form.Field
                              name="birthDate"
                              validators={[
                                Validators.Required('Campo obrigatório'),
                                Validators.DateRegex('Insira uma data válida')
                              ]}
                            >
                              <FloatingLabel label={'Data de Nascimento'}>
                                <TextField
                                  id="text-field-birth-date"
                                  kind="custom"
                                  options={{ mask: '99/99/9999' }}
                                  autoComplete={'bday'}
                                />
                              </FloatingLabel>
                            </Form.Field>

                            <Separator />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        {' '}
                        <Row mb center="xs">
                          <Col xs>
                            <Form.Field
                              name="cpf"
                              // validators={[Validators.CpfRegex('O CPF preenchido não é válido')]}
                            >
                              <FloatingLabel label={'CPF (opcional)'}>
                                <TextField id="text-field-cpf" type={'text'} kind="cpf" />
                              </FloatingLabel>
                            </Form.Field>

                            <Separator />
                          </Col>
                        </Row>
                        <Row mb center="xs">
                          <Col xs>
                            <Form.Field
                              name="birthDate"
                              // validators={[Validators.DateRegex('Insira uma data válida')]}
                            >
                              <FloatingLabel label={'Data de Nascimento (opcional)'}>
                                <TextField
                                  id="text-field-birth-date"
                                  kind="custom"
                                  options={{ mask: '99/99/9999' }}
                                  autoComplete={'bday'}
                                />
                              </FloatingLabel>
                            </Form.Field>

                            <Separator />
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row mb center="xs">
                      <Col xs>
                        <Form.Field
                          name="contactPreference"
                          label="Preferência de Contato"
                          validators={[Validators.Required('Campo obrigatório')]}
                          initialValue={RadioOptions.email}
                          onValueChange={value => handleRadioChange(value)}
                        >
                          <Separator />

                          <Row>
                            <Col xs={12}>
                              <RadioField mb id={RadioOptions.email}>
                                E-mail
                              </RadioField>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12}>
                              <RadioField mb id={RadioOptions.telefone}>
                                Telefone
                              </RadioField>
                            </Col>
                          </Row>
                        </Form.Field>
                      </Col>
                    </Row>

                    <Row mb center="xs">
                      <Col xs={12}>
                        <Form.Field
                          name="email"
                          onValueChange={email => {
                            setEmailValue(email)
                          }}
                          validators={[
                            Validators.Required('Campo obrigatório'),
                            Validators.EmailRegex('O e-mail preenchido não é válido')
                          ]}
                        >
                          <FloatingLabel label={'E-mail'}>
                            <TextField id="text-field-email" type={'email'} autoComplete="email" />
                          </FloatingLabel>
                        </Form.Field>

                        <Separator />
                      </Col>

                      <Col xs={12}>
                        <Form.Field
                          name="phone"
                          onValueChange={phone => {
                            setTelefoneValue(phone)
                          }}
                          validators={[
                            Validators.Required('Campo obrigatório'),
                            Validators.PhoneRegex('O telefone preenchido não é válido')
                          ]}
                        >
                          <FloatingLabel label={'Telefone'}>
                            <TextField
                              id="text-field-phone"
                              type={'tel'}
                              kind={'cel-phone'}
                              autoComplete="tel"
                            />
                          </FloatingLabel>
                        </Form.Field>

                        <Separator />
                      </Col>
                    </Row>

                    <Row mb center="xs">
                      <Col xs>
                        <Form.Field name="fileNumber">
                          <FloatingLabel label={'Número da ficha (opcional)'}>
                            <TextField id="text-field-token-number" type={'text'} />
                          </FloatingLabel>
                        </Form.Field>
                        <Separator />
                      </Col>
                    </Row>

                    <Row mb center="xs">
                      <Col xs>
                        <Form.Field name="company">
                          <FloatingLabel label={'Empresa (opcional)'}>
                            <TextField id="text-field-enterprise" type={'text'} />
                          </FloatingLabel>
                        </Form.Field>
                        <Separator />
                      </Col>
                    </Row>

                    <Row mb center="xs">
                      <Col xs>
                        <Form.Field
                          name="message"
                          validators={[Validators.Required('Campo obrigatório')]}
                        >
                          <FloatingLabel label={'Mensagem'}>
                            <TextAreaField />
                          </FloatingLabel>
                        </Form.Field>

                        <Separator />
                      </Col>
                    </Row>

                    <Row mb>
                      <Col xs={12} sm={12} md={4} lg={4}>
                        <Button
                          id="button-send-email-contact"
                          type="submit"
                          kind="primary"
                          loading={state.loading}
                          expanded
                        >
                          Enviar e-mail
                        </Button>

                        <Separator />
                      </Col>
                    </Row>
                  </Form>
                )
              }}
            </HttpPostContainer>
          </Col>

          <Col xs={12} md={4} mdOffset={1} lg={4}>
            <H2>{metadata.faqTitulo}</H2>
            <div dangerouslySetInnerHTML={{ __html: metadata.faqConteudo }} />
            <Separator />
            <Divisor />
            <Separator />
            {process.env.GATSBY_MARCA !== 'FLEURY' && (
              <>
                <H2>{metadata.atendimentoTelefoneTitulo}</H2>
                <div dangerouslySetInnerHTML={{ __html: metadata.atendimentoTelefoneConteudo }} />
                <Separator />
                <Divisor />
                <Separator />
              </>
            )}
            <H2>{metadata.atendimentoOnlineTitulo}</H2>
            <div dangerouslySetInnerHTML={{ __html: metadata.atendimentoOnlineConteudo }} />
            <Separator />
            <Row>
              <Col xs={12} sm={12} md={8} lg={6}>
                <StaticQuery
                  query={graphql`
                    {
                      cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
                        metadata {
                          chatOnlineLink
                          chatOnlineLinkMedico
                        }
                      }
                    }
                  `}
                  render={(agendamentoData: Query) => {
                    const url = props.isDoctor
                      ? agendamentoData.cosmicjsInformacoesEstaticas.metadata.chatOnlineLinkMedico
                      : agendamentoData.cosmicjsInformacoesEstaticas.metadata.chatOnlineLink
                    if (!url) {
                      return null
                    }
                    return (
                      <Button id="button-access-chat" to={url} external kind="secondary" expanded>
                        Acessar Chat
                      </Button>
                    )
                  }}
                />
              </Col>
            </Row>

            <Separator />
          </Col>
        </Row>
      </Grid> */}
    </>
  )
}

const buildEmail = (data: ContactPageFormData): string => {
  const topics = [
    data.name && {
      title: 'Nome Completo',
      content: data.name
    },
    data.cpf && {
      title: 'CPF',
      content: data.cpf
    },
    data.birthDate && {
      title: 'Data de nascimento',
      content: data.birthDate
    },
    data.email && {
      title: 'E-mail',
      content: data.email
    },
    data.phone && {
      title: 'Telefone',
      content: data.phone
    },
    data.company && {
      title: 'Empresa',
      content: data.company
    },
    data.fileNumber && {
      title: 'Numero da ficha',
      content: data.fileNumber
    },
    data.message && {
      title: 'Mensagem',
      content: data.message
    }
  ]
  const emailContent = buildEmailHTML(topics)
  return emailContent
}
